import {
  DeliveryTimeCode,
  ISrpTransformResponseV2,
  SrpKeys,
} from "../dto/searchResults.types";
import { getLineAttributesDetectedV2 } from "../helpers";

export const transformResponseSrp = (result: ISrpTransformResponseV2[]) => {
  return {
    products: result.map<ISrpTransformResponseV2>(
      (product) =>
        ({
          ...product,
          deliveryTime: product.deliveryTime ?? DeliveryTimeCode.NOT_AVAILABLE,
        }) as ISrpTransformResponseV2
    ),
    size: result.length,
    attributesLineDetection: getLineAttributesDetectedV2(result),
  };
};

export const projectionMap: Record<string, SrpKeys> = {
  Net_price: "netPrice",
  BasePrice: "basePrice",
  OldPrice: "oldPrice",
  Reviews: "rating",
  Rating: "rating",
  TAX: "tax",
};

export const srpProjectionMapper = (
  projection: (keyof ISrpTransformResponseV2)[]
) => {
  return [
    ...new Set(
      projection.map((attribute) => {
        return projectionMap[attribute] ?? attribute;
      })
    ),
  ];
};
